<template>
    <div>
        <div id="table">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item>订单管理</el-breadcrumb-item>
                <el-breadcrumb-item>订单客服</el-breadcrumb-item>
                <el-breadcrumb-item>批量导入</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="screen">

                <el-upload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :action="action" :limit="1" :headers="headers" :on-success="handelUploadSuccess">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    <el-button style="margin-left: 10px;" size="small" type="success" :loading="loading" @click="submitImport">开始导入</el-button>
                    <el-button style="margin-left: 10px;" size="small" type="info"  @click="handleDownloadFile">下载模板</el-button>
                </el-upload>

                <div v-if="faileds.length > 0">
                    失败订单如下
                    <p v-for="(item, index) in faileds" :key="index">{{index + 1}} ---{{item.title}} ----- <span style="color:red">{{item.failed}}</span></p>
                </div>

                <iframe :src="iframe" style="display:none"></iframe>

            </div>
        </div>
    </div>
</template>

<script>
    import { ordersImport } from '@/api'
    
    export default {
        name: 'views.orders.Imports',
        inject: ['handelLoading'],
        data(){
            return {
                file:{},
                iframe:null,
                faileds:[],
                loading:false,
                action:this.$global.BASE_API_URL + 'api/basics/temporary?path=import',
                // action:'http://test-api.dmhglasses.com/' + 'api/basics/temporary?path=import',
                // 
            }
        },
        computed:{
            headers:function (){
                return this.$util.uploadHeaders()
            }
        },
        methods:{
            handelUploadSuccess(response){
                if(!response.state){
                    this.$notify.error({title: '提示',message: response.message})
                    return
                }
                this.file.file = response.response.file.name;

            },
            submitImport(){
                if(!this.file.file){
                    this.$notify.error({title: '提示',message: '请选择导入文件'})
                    return
                }
                // this.loading = true
                this.handelLoading(true)
                //console.log(this.file);
                this.faileds = [];
                ordersImport(this.file).then((response)=>{
                    // this.loading = false
                    if(!response.state){
                        this.handelLoading(this.loading = false)
                        this.$notify.error({title: '提示',message: response.message})
                        return
                    }
                    this.handelLoading(this.loading = false)
                    this.faileds = response.response.faileds
                    this.$notify.success({title: '提示',message: response.message})
                }).catch(() => {
                    this.loading = false
                })
            },
            handleDownloadFile(){
                this.iframe = this.$global.BASE_API_URL + 'storage/order_kf.xlsx?time' + Date.now()
                // this.iframe = 'http://test-api.dmhglasses.com/public/' + 'storage/order_kf.xlsx?time' + Date.now()
            }
        },
        created() {

        }
      }
</script>
<style lang="scss" scoped>
    #table{
        .screen {
            padding: 20px 10px 10px 20px;
            border: 1px solid #e6e6e6;
            border-radius: 10px;
            margin-bottom: 20px;
        }
        .table{
            border: 1px solid #e6e6e6;
            border-radius: 10px;
        }
    }
</style>

<style lang="scss">
    @import '@/assets/scss/table.scss';
</style>
